import { graphql } from 'gatsby'
import React from 'react'
import Seo from '../components/Shared/Seo/Seo'

const PageThankYou = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom: {
            seoTitle,
            slug,
            seoDescription
        },
        allContentfulPageCustom: {
            nodes: allLinks
        }
    }
}) => {
    return (
        <div className='container'>
            <Seo slug={slug} lang={pageContext.node_locale} title={seoTitle} description={seoDescription} hrefLangs={allLinks} />
            <h1 className='custom-page-title'>{seoTitle}</h1>
        </div>
    )
}

export const ThankYouQuery = graphql`
query thankYouQuery($id: String!, $contentful_id: String!){
    contentfulPageCustom(id: {eq: $id}){
        seoTitle
        seoDescription
        slug
    }
    allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}}){
        nodes {
            slug
            node_locale
        }
    }
}
`


export default PageThankYou